<template>
    <div class="event-block mb-3 w-100">
        <div @click="setShowMeetingMenuValue()"
            class="py-3 cursor-pointer event-block-header subTitle"
            :class="{'border-style': showTodayMeetings}"
            >
            <div class="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24v40H64C28.7 64 0 92.7 0 128v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64h-40V24c0-13.3-10.7-24-24-24s-24 10.7-24 24v40H152V24zM48 192h352v256c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>
                <div class="subTitle ml-2 mr-3 event-block-title">
                    {{staticText.todayVideoMeetingLabel}}
                </div>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'about-calendar-events-22320323'" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
                <b-tooltip
                  target="about-calendar-events-22320323"
                  :title="aboutVideoMeetingHoverText"
                  :delay="{ show: 100, hide: 400 }"
                  triggers="hover"
                />
            </div>
            <div class="d-flex mx-2">
                <i v-if="showTodayMeetings" :id="'chevron-icon-22'"
                class="fas fa-chevron-up"
                />
                <i v-else :id="'chevron-icon-222'"
                    class="fas fa-chevron-down"
                />
            </div>
        </div>
        <div v-if="showTodayMeetings" class="event-body px-3 pb-3">
            <div v-if="loadingMeetings" class="py-1">
                <progress-indicator :loadingLabel="staticText.loadingMeetingsLabel"></progress-indicator>
            </div>
            <div v-else-if="meetingList.length">
                <div class="py-3 select-meeting-title">
                    {{staticText.selectMeetingLabel}}
                </div>
                <b-collapse  v-model="showTodayMeetings" class="px-3 meeting-box">
                    <b-form-group class="bubble-menu__card__radio-group">
                        <b-form-radio
                            v-for="(item, index) in meetingList"
                            :key="index"
                            v-model="currentMeetingUrl"
                            :value="item.meeting_link"
                            class="custom-radio-btn-label mb-3"
                            @change="$emit('setMeetingUrl', currentMeetingUrl)"
                        >
                            <template>
                                <div class="w-100 d-flex align-items-center">
                                    <img
                                        :src="getMeetingProviderIcon(item)"
                                        height="20"
                                        width="20"
                                        svg-inline
                                    />
                                    <div class="d-flex align-items-center ml-2">
                                        <span class="mr-3 event-time">{{ item.formatted_start_time }}</span>
                                        <span class="event-title"> {{item.title}}</span>
                                    </div>
                                    <div v-if="item.meeting_progress > 0 && item.meeting_progress < 100" class="py-1">
                                        <b-progress height="4px" class="w-100" :striped="true" :animated="true">
                                            <div class="w-100 d-flex">
                                                <div class="meeting-progress" :style="{width: `${item.meeting_progress}%`}">
                                                </div>
                                              </div>
                                        </b-progress>
                                    </div>
                                </div>
                            </template>
                        </b-form-radio>
                    </b-form-group>
                </b-collapse>
            </div>
            <div v-else class="no-meeting">
                {{ staticText.noMeetingsFound }}
            </div>
            
        </div>
    </div>
</template>

<script>

import { getTodayEventsApi } from "@/apps/call/api"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
export default({
    name: 'CalendarEventBlock',
    components: {
        ProgressIndicator
    },
    data() {
        return {
            currentMeetingUrl: null,
            prevSelectedMeetingUrl: null,
            showTodayMeetings: false,
            loadingMeetings: false,
            linkedCalendarAccounts: '',
            SHOW_MEETINGS_MENU: 'show_meetings_menu',
            meetingList: [],
            googleMeetKey: "meet.google.com",
            msTeamsKey: "teams.microsoft.com",
            staticTextDefault: {
                aboutMeetingsBeforeEmailText: "Select your current video meeting to send the bao Co-Pilot to this meeting to record it. Video meetings from",
                aboutMeetingsAfterEmailText: "are displayed.",
                todayVideoMeetingLabel: "Today’s Video-Meetings",
                loadingMeetingsLabel: "Loading Meetings …",
                selectMeetingLabel: "Choose a meeting for your bao Co-Pilot to join",
                noMeetingsFound: "No upcoming meetings for today"
            }
        }
    },
    computed: {
        staticText () {
            return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
        },
        aboutVideoMeetingHoverText() {
            return this.staticText.aboutMeetingsBeforeEmailText + ' ' + this.linkedCalendarAccounts + ' ' + this.staticText.aboutMeetingsAfterEmailText
        }
    },
    methods: {
        getMeetingProviderIcon (item) {
            if (item.meeting_link.includes(this.googleMeetKey)) {
                return require("@/assets/svgs/google-meet.svg")
            } else if(item.meeting_link.includes(this.msTeamsKey)) {
                return require("@/assets/svgs/ms-teams.svg")
            }
            else {
                return ""
            }
        },
        getShowMeetingsMenuValue() {
            this.showTodayMeetings = !!JSON.parse(localStorage.getItem(this.SHOW_MEETINGS_MENU)) || false
        },
        setShowMeetingMenuValue() {
            this.showTodayMeetings = !this.showTodayMeetings
            localStorage.setItem(this.SHOW_MEETINGS_MENU, this.showTodayMeetings);
        },
        getMeetingProgress(item) {
            const currentTime = new Date()
            const startTime = new Date(item.start_at)
            const endTime = new Date(item.end_at)

            if (currentTime < startTime) {
                return 0
            } else if (currentTime > endTime) {
                return 100
            } else {
                const totalDuration = endTime - startTime
                const elapsedTime = currentTime - startTime
                return (elapsedTime / totalDuration) * 100
            }
        },
        getTimeInHumanReadableFormat(datetime) {
            const date = new Date(datetime)
            let hours = date.getHours()
            const minutes = date.getMinutes()
            const period = hours >= 12 ? 'PM' : 'AM'
            hours = hours % 12 || 12
            const formattedHours = hours.toString().padStart(2, '0')
            const formattedMinutes = minutes.toString().padStart(2, '0')
            return `${formattedHours}:${formattedMinutes} ${period}`
        },
        async loadAllMeetings() {
            this.loadingMeetings = true
            try{
                const params = {
                    timezone_offset_minutes: -1*(new Date().getTimezoneOffset())
                }
                const resp = await getTodayEventsApi(params)
                this.loadingMeetings = false
                for(let idx=0; idx<resp.data.length; idx++){
                    this.meetingList = [...this.meetingList, ...resp.data[idx].events_list]
                    if (this.linkedCalendarAccounts){
                        this.linkedCalendarAccounts += ", " + resp.data[idx].email
                    } else {
                        this.linkedCalendarAccounts = resp.data[idx].email
                    }
                }
                this.meetingList.sort((a, b) => 
                    new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
                )
                this.meetingList.forEach(item => {
                    item.formatted_start_time = this.getTimeInHumanReadableFormat(item.start_at),
                    item.meeting_progress = this.getMeetingProgress(item)
                })
            }
            catch {
                // 
            } finally {
                this.loadingMeetings = false
            }
        }
    },
    mounted() {
        this.getShowMeetingsMenuValue()
        this.loadAllMeetings()

    }
})
</script>

<style lang="scss" scoped>

.event-block{
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
}
.event-block-header{
    background-color: #EFEFEF;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    border-radius: 20px 20px 20px 20px;
    color:  rgba(42, 45, 82, 0.8);
}
.event-body{
    
}
.event-title{
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 700;
    color: #555775;
}
.event-time{
    color: #7F8197;
    white-space: nowrap;
}
.border-style{
    border-radius: 20px 20px 0 0;
}
.event-block-title{
    color: rgba(42, 45, 82, 0.8);
    font-weight: 700;
}
.meeting-progress{
    background-color: #ff3c33;
}
.select-meeting-title{
    color: #0b115d99
}
.no-meeting{
    color: #0b115d99;
    padding: 24px 0 24px 0;
}
.custom-radio-btn-label{
    :deep(.custom-control-label){
        width: 100% !important;
    }
}
.meeting-box{
    max-height: 145px;
    overflow-y: auto;
}
</style>